import axios from 'axios';
import environment from '@/environment.js';

export default {
  generatePageAccessToken(user_id, fb_exchange_token, botId, platform) {
    return axios.post(
      `${environment.url_env_bot}/graph_api/generate_page_access_token`,
      {
        user_id,
        fb_exchange_token,
        botId,
        platform,
      },
    );
  },
  deletePageConnection(botId, page_id, platform, access_token) {
    return axios.post(
      `${environment.url_env_bot}/graph_api/delete_page_connection`,
      {
        botId,
        page_id,
        platform,
        access_token,
      },
    );
  },
  deleteWhatsAppConnection(botId, waba_id, access_token) {
    return axios.post(
      `${environment.url_env_bot}/graph_api/delete_whatsapp_connection`,
      {
        botId,
        waba_id,
        access_token,
      },
    );
  },
  // for whatsapp business with meta
  generateCredentialsWhatsapp(user_id, fb_exchange_token, bot_id) {
    return axios.post(
      `${environment.url_env_bot}/graph_api/whatsapp/generate_credentials`,
      {
        user_id,
        fb_exchange_token,
        bot_id,
      },
    );
  },
  getUserInformation(accessToken) {
    return axios.get(
      `${environment.url_env_bot}/graph_api/get_meta_user_information?access_token=` +
        accessToken,
    );
  },
  getBusinessPages(accessToken) {
    return axios.get(
      `${environment.url_env_bot}/graph_api/business_pages?access_token=` +
        accessToken,
    );
  },
  listCatalogs(business_id, accessToken) {
    return axios.get(
      `${environment.url_env_bot}/graph_api/list_catalogs?access_token=${accessToken}&business_id=${business_id}`,
    );
  },
  createCatalog(name, business_id, accessToken) {
    return axios.post(
      `${environment.url_env_bot}/graph_api/create_catalog?access_token=${accessToken}`,
      {
        name,
        business_id,
      },
    );
  },
  listProductsByCatalog(catalog_id, accessToken) {
    return axios.get(
      `${environment.url_env_bot}/graph_api/list_products_by_catalog/${catalog_id}?access_token=${accessToken}`,
    );
  },
  createProductCatalog(body, catalog_id, accessToken) {
    return axios.post(
      `${environment.url_env_bot}/graph_api/create_product_catalog?access_token=${accessToken}&catalog_id=${catalog_id}`,
      body,
    );
  },

  updateProductCatalog(id, body, accessToken) {
    return axios.put(
      `${environment.url_env_bot}/graph_api/update_product_catalog/${id}?access_token=${accessToken}`,
      body,
    );
  },
  deleteProductCatalog(id, accessToken) {
    return axios.delete(
      `${environment.url_env_bot}/graph_api/delete_product_catalog/${id}?access_token=${accessToken}`,
    );
  },
};
