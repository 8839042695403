var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{key:_vm.selected_bot.id,class:{
    history_conversations: true,
    history_item3: _vm.show_client_info,
  }},[_c('div',{class:{
      'item item-1 pb-0': true,
      hidden: _vm.showMobileViewChat || _vm.show_client_info,
      showMobileMode: !_vm.showMobileViewChat && !_vm.show_client_info,
    }},[_c('ChatCards',{attrs:{"conversations":_vm.conversations,"tags":_vm.tags,"lead":_vm.lead,"isLoadingConversations":_vm.isLoadingConversations,"itemsPerPage":_vm.itemsPerPage,"page":_vm.page,"pages":_vm.pages,"selectedConversation":_vm.selectedConversation},on:{"onSelectedConversation":_vm.selectConversation,"onAddCategoryFilter":function($event){_vm.selectedTags = $event},"onAddCalificationFilter":function($event){_vm.selectedCalifications = $event},"onSearchChat":function($event){return _vm.onSearchChat($event, 1)},"changePage":function($event){_vm.page = $event},"changeItemsPerPage":function($event){_vm.itemsPerPage = $event},"sendDate":_vm.getFilterDate}})],1),_c('div',{class:{
      'item item-2 fadeItemMobile': true,
      hidden: !_vm.showMobileViewChat,
      showMobileMode: _vm.showMobileViewChat,
    }},[_c('ViewConversation',{attrs:{"selectedConversation":_vm.selectedConversation,"sessions":_vm.sessions,"isLoadingSessions":_vm.isLoadingSessions,"itemsPerPageSession":_vm.itemsPerPageSession,"pageSession":_vm.pageSession,"pagesSession":_vm.pagesSession,"totalSessions":_vm.totalSessions},on:{"changePage":function($event){_vm.pageSession = $event},"changeItemsPerPage":function($event){_vm.itemsPerPageSession = $event},"openItem3":_vm.openItem3,"changeDate":function($event){_vm.selectedDate = $event},"closeMobile":function($event){_vm.showMobileViewChat = false}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_client_info),expression:"show_client_info"}],class:{
      'item item-3 fadeItemMobile': true,
      hidden: !_vm.show_client_info,
      showMobileMode: _vm.show_client_info,
    }},[_c('InfoSession',{attrs:{"isLoadingInfoSession":_vm.isLoadingInfoSession,"show":true,"selectedConversation":_vm.selectedConversation,"type":_vm.sessionType,"sessionData":_vm.sessionData,"sessionId":_vm.sessionId}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }