<template>
  <div class="h-100 view-history-status" :key="selected_bot.id">
    <SpinnerLoading v-if="isLoadingHistory" />
    <template v-else>
      <section v-if="!selectedAgent" class="no-data">
        <div class="space">
          <img src="/img/icons/5-clientes.svg" style="width: 120px" alt="" />
          <span class="my-4" style="font-size: 1.5rem;">
            Seleccione agente
          </span>
        </div>
      </section>
      <section class="h-100 d-flex flex-column justify-content-between" v-else>
        <div>
          <div class="header_box">
            <div class="row align-items-center">
              <div @click="$emit('closeMobile')" class="mobile">
                <span class="i-base px-2 py-1 mr-2 negrita">{{ `<-` }}</span>
              </div>
              <img
                  :src="selectedAgent.user.image || '/img/brand/0-perfil-agente.png'"
                  class="agent_image"
                />
              <h3 class="m-0 mobile-title">Estados de {{agentName(selectedAgent.user.name)}}</h3>
              <!-- <span>Hoy {{ $moment().locale("es").format("DD MMMM YYYY") }}</span> -->
              <span v-if="!rangeDate.fromDate && !rangeDate.endDate">Hoy {{actualDate}}</span>
              <span v-else>Desde {{ $moment(rangeDate.fromDate).locale('es').format('DD/MM/YYYY') }} - Hasta {{ $moment(rangeDate.endDate).locale('es').format('DD/MM/YYYY') }}</span>
            </div>
            <div class="row aling-items-center">
              <div
                class="d-flex align-items-center h-100 w-100"
              >
                <div
                  class="agent_status_circle"
                  :style="{ backgroundColor: colorCircle(selectedAgent.agent_status) || 'grey' }"
                >
                </div>
                <span>
                  {{ translateStatus(selectedAgent.agent_status) }}
                </span>
              </div>
            </div>
          </div>
          <div v-if="history.length > 0" >          
          <section class="table buefy tabla" >
            <b-table
              :range-before="rangeBefore"
              :range-after="rangeAfter"
              :data="history"
              :per-page="perPage"
              :current-page.sync="currentPage"
              :pagination-simple="true"
              :pagination-position="'bottom'"
              :default-sort-direction="'desc'"
              :sort-icon="'caret-up'"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
              icon-pack="fas"
              :key="tableKey"
              :selected.sync="selectedRow"
              :sticky-header="true"
              height="500"
              focusable
            >
              <template slot-scope="props">
                <b-table-column
                  field="states"
                  label="Estados"
                  width="300"
                >
                  <div
                    class="d-flex align-items-center h-100 w-100"
                  >
                    <div
                      class="agent_status_circle"
                      :style="{ backgroundColor: colorCircle(props.row.agent_status) || 'grey' }"
                    >
                    </div>
                    <p>
                      {{ translateStatus(props.row.agent_status) }}
                    </p>
                    <!-- ESTE IF SIGNIFICA QUE EL ESTADO QUE SE MUESTRA ES EL QUE TIENE ACTUALMENTE EL AGENTE YA QUE NO TIENE TIEMPOS DE FIN -->
                    <p 
                      v-if="!props.row.calculated_time && !props.row.end_time" 
                      class="actual_state py-1 px-2 ml-2"
                    >
                      Actual
                    </p>
                  </div>
                </b-table-column>
                <b-table-column
                  field="start-time"
                  label="Comienzo"
                >
                  <p>{{ $moment(props.row.start_time).locale('es').format('DD/MM(h:mm a)') }}</p>
                </b-table-column>
                <b-table-column
                  field="end-time"
                  label="Término"
                >
                  <p>{{ props.row.end_time ? $moment(props.row.end_time).locale('es').format('DD/MM(h:mm a)') : '-' }}</p>
                </b-table-column>
                <b-table-column
                  field="duration"
                  label="Duración del estado"
                >
                  <p>{{ props.row.calculated_time ? formatDuration(props.row.calculated_time) : '-' }}</p>
                </b-table-column>
              </template>
            </b-table>
          </section>
          <section class="table-footer d-flex align-items-center justify-content-between p-4">
              <div class="col-md-4 desktop">
                <span class="text_sm mr-3 mb-0">
                  {{ totalItems == 1 ? `${totalItems} registro` : `${totalItems} registros` }}
                </span>
              </div>
              <div class="col-md-6">
                <Pagination
                  :itemsPerPage="itemsPerPage"
                  :page="actualPage"
                  :pages="pages"
                  itemLabel="registros"
                  @changePagination="$emit('changePagination', $event)"
                />
              </div>
            </section>
          </div>
          <div v-else class="info_box">
            <p class="text-center h-100 w-100">
              No hay registro del día de hoy
            </p>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import { FadeTransition } from 'vue2-transitions/dist/vue2-transitions.cjs';
import { mapState } from 'vuex';
import { capitalizeFirstLetter } from '@/utils/utils.js';
import '../../../assets/buefy_class.scss';
import '../../../assets/main.scss';
import Pagination from '../../V2/DatabotLive/components/Pagination.vue';

export default {
  props: [
    'history',
    'isLoadingHistory',
    'selectedAgent',
    'itemsPerPage',
    'actualPage',
    'pages',
    'totalItems',
    'rangeDate'
  ],
  components: {
    FadeTransition,
    Pagination
  },
  data() {
    return {
      // TABLA
      rangeBefore: 1,
      rangeAfter: 1,
      perPage: 5,
      currentPage: 1,
      tableKey: 0,
      selectedRow: {},
      hoverStatusIndex: null,
    };
  },
  computed: {
    ...mapState(['selected_bot']),
    actualDate() {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      let date = new Date().toLocaleDateString('es-CL', options);
      return date
    }
  },
  methods: {
    agentName(agent) {
      return capitalizeFirstLetter(agent)
    },
    formatDuration(duration) {
      const splited = duration.split(":")
      let result = ''
      if (Number(splited[0]) > 0) result = `${splited[0]} hrs`
      if (Number(splited[1]) > 0) result = `${result} ${splited[1]} min`
      if (Number(splited[2]) > 0) result = `${result} ${splited[2]} seg`
      if(result == '') result = '1 seg'
      return result
    },
    translateStatus(status) {
      const dictionary = [
        {
          status: "close_session",
          name: "Sesión cerrada"
        },
        {
          status: "active",
          name: "Conectado"
        },
        {
          status: "inactive",
          name: "Desconectado"
        },
        {
          status: "absent",
          name: "Ausente"
        },
        {
          status: "do_not_disturb",
          name: "No interrumpir"
        },
      ]
      const statusFound = dictionary.find(e => e.status == status)?.name
      return statusFound
    },
    colorCircle(status) {
      const colors = [
        {
          status: 'active',
          color: '#00D100'
        },
        {
          status: 'absent',
          color: '#efb810'
        },
        {
          status: 'inactive',
          color: '#d44646'
        },
      ]
      const colorFound = colors.find(e => e.status == status)?.color
      return colorFound
    }
  }
};
</script>


<style lang="scss">
.view-history-status {
  .table {
    border: none;

    .table-mobile-sort {
      display: none !important;
    }
  }
}
</style>

<style lang="scss">
.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  height: calc(100vh - 80px);
  overflow-y: hidden;

  .space {
    margin: auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      width: 70%;
      text-align: center;
    }
  }
}
.view-history-status {
  .user-img,
  .user-img-wsp,
  .user-img-instagram {
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50rem;
    background-color: #2981ef;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 700;
  }
  .table-footer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    padding: 1rem 2rem;
    border-top: 1px solid #e8e8e8;
    background-color: #fafafa;
  }
  .user-img-wsp {
    background-color: #9edd63;
    background-image: url('/img/icons/18-i-whatsapp-b.svg');
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: 1rem 100%;
  }
  .user-img-instagram {
    background-color: #f5365c;
    background-image: url('/img/icons/31-i-instagram-b.svg');
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: 1rem 100%;
  }
  .header_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem;

    @media (max-width: 768px) {
      margin: .75rem 1rem;
    }
  }
  .mobile-title {
    padding-inline: 1rem;

    @media (max-width: 768px) {
      padding-left: .75rem;
      padding-right: 0;
      width: 70%;
      font-size: 1rem;
    }
  }
  .mobile {
    @media (min-width: 1280px) {
      display: none;
    }
  }
  .desktop {
    @media (max-width: 1279px) {
      display: none;
    }
  }
  .agent_status_circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
  }
  .agent_image {
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50rem;
    justify-content: center;
    align-items: center;
  }
  .tabla {
    overflow-y: scroll;
    height: 75vh;
  }
  .actual_state {
    background-color: #2981ef;
    color: #fff;
    border-radius: 10px;
    font-size: 0.7rem;
  }
}
</style>
