<template>
 <section
    :key="selected_bot.id"
    :class="{
      agent_status_history: true,
      item3: show_client_info,
    }"
  >
    <div
      :class="{
        'item item-1 pb-0': true,
        hidden: showMobileViewChat || show_client_info,
        showMobileMode: !showMobileViewChat && !show_client_info,
      }"
    >
      <AgentsList
        :selectedAgent = "selectedAgent"
        :agents = "listAgents"
        :isLoadingAgents = "isLoadingAgents"
        @onSelectedAgent = "selectAgent"
        @onSearchChat = "searchAgent"
        @openDateModal = "show_date_filter = true"
      />
    </div>
    <div
      :class="{
        'item item-2 fadeItemMobile': true,
        hidden: !showMobileViewChat,
        showMobileMode: showMobileViewChat,
      }"
    >
      <HistoryTable
        :history = "history"
        :isLoadingHistory = "false"
        :selectedAgent = "selectedAgent"
        :itemsPerPage="itemsPerPage"
        :actualPage="currentPage"
        :pages="pages"
        :totalItems="totalItems"
        :rangeDate="{fromDate,endDate}"
        @changePagination="changePagination"
      />
    </div>
    <DateFilterModal
      :showFilterModal="show_date_filter"
      @closeModal="show_date_filter = false"
      @filterDate="sendDate"
    />
  </section>
</template>

<script>
import axios from 'axios';
import { mapState, mapMutations } from 'vuex';
import AgentsList from './components/AgentsList.vue';
import HistoryTable from './components/HistoryTable.vue';
import environment from '@/environment.js';
import dashboard_api from '@/dashboard_api.js';
import DateFilterModal from '../widgetWhatsapp/components/DateFilterModal.vue';

export default {
  components: {
    AgentsList,
    HistoryTable,
    DateFilterModal
  },
  data() {
    return {
      selectedAgent: null,
      agents: [],
      isLoadingAgents: false,
      showMobileViewChat: false,
      history: [],
      isLoadingHistory: false,
      listAgents: [],
      show_date_filter: false,
      itemsPerPage: "15",
      currentPage: 1,
      pages: 1,
      totalItems: 1,
      fromDate: '',
      endDate: ''
    }
  },
  async mounted() {
    await this.initialize();
  },
  computed: {
    ...mapState([
      'user',
      'selected_bot',
      'show_client_info'
    ])
  },
  methods: {
    ...mapMutations(['setShowClientInfo']),
    searchAgent(val) {
      // console.log(val);
      // if(val.length > 0) {
      //   this.listAgents = this.listAgents.filter(e => e.name.includes(val))
      // } else {
      //   this.listAgents = this.agents
      // }
    },
    async initialize() {
      try {
        this.isLoadingAgents = true
        await this.getAgents()
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingAgents = false
      }
    },

    async getAgents() {
      try {
        this.listAgents = (
          (await dashboard_api.get(`/userbot/user_for_bot/${this.selected_bot.id}`)
        )).data.filter(e => e.role.name === "agente");
      } catch (error) {
        console.log("Error getAgents => ", error);
      }
    },
    async sendDate(event) {
      const { init, end } = event
      if(init && end) {
        this.fromDate = init
        this.endDate = end
        this.show_date_filter = false
      }
      if(this.selectAgent) {
        this.selectAgent(this.selectedAgent)
      }
    },
    async changePagination(event) {
      this.currentPage = event.page
      this.itemsPerPage = event.itemPerPage
      await this.selectAgent(this.selectedAgent)
    },
    async selectAgent(agent) {
      this.selectedAgent = agent
      let payload = {
        "page": this.currentPage,
        "items": this.itemsPerPage
      }
      if(this.fromDate && this.endDate) {
        payload.from = this.fromDate
        payload.to = this.endDate
      }
      const res = await dashboard_api.post(`/agent_availables/history_available_by_agent/${ this.selected_bot.id }/${ agent.user_id }`, payload) 
      this.totalItems = res.data.quantity
      this.pages = res.data.pages
      this.history = res.data.data
    }
  }
}
</script>

<style lang="scss" scoped>
.agent_status_history {
  position: relative;
  display: grid;
  grid-template-columns: 460px calc(100% - 460px);
  background-color: #fff;
  max-height: 100%;
  overflow: hidden;
  height: calc(100vh - 80px);

  @media (max-width: 1279px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .item-1 {
    position: inherit;
    padding: 1.5rem 1rem;
    border-right: 1px solid #e8e8e8;
    background-color: #fff;
    max-height: calc(100vh - 80px);
    overflow-y: hidden;
    z-index: 1;

    @media (max-width: 1600px) {
      padding: 1.5rem 0.25rem;
    }

    @media (max-width: 1279px) {
      padding: 1.5rem 1rem;
    }

    @media (max-width: 1024px) {
      border-right: 0;
    }
  }

  .item-2 {
    position: inherit;
    padding: 0;
    background-color: #fff;
    border-right: 1px solid #e8e8e8;
    max-height: calc(100vh - 80px);
    overflow-y: hidden;

    @media (max-width: 1279px) {
      z-index: 2;
    }

    @media (max-width: 1024px) {
      border-right: 0;
    }
  }

  .item-3 {
    position: inherit;
    padding: 1.5rem 0;

    @media (max-width: 1600px) {
      padding: 1.5rem 0.25rem;
    }

    @media (max-width: 1279px) {
      z-index: 3;
    }
  }

  .fadeItemMobile {
    animation-name: test;
    animation-duration: 0.25s;
  }

  .fadeLeaveItemMobile {
    animation-name: test2;
    animation-duration: 0.25s;
  }

  .showMobileMode {
    @media (max-width: 1279px) {
      display: inline-block !important;
      height: 100%;
    }
  }

  .hidden {
    @media (max-width: 1279px) {
      display: none !important;
    }
  }
}
.history_item3 {
  grid-template-columns: 460px 2fr 460px;

  @media (max-width: 1600px) {
    grid-template-columns: 380px 2fr 380px;
  }
  @media (max-width: 1279px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}
@keyframes test {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes test2 {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 1;
    transform: translateX(100%);
  }
}
</style>
