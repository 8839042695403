<template>
  <Modal
    class="modal-2"
    alto="500"
    ancho="800"
    :show="show"
    @close="$emit('close', (search = ''), (selectTag = ''), (values = [])), reset()"
  >
    <template v-if="show" slot="content">
      <section class="enviar-plantilla">
        <div v-if="show_date_menu" class="opacity"></div>
        <div class="col-lg-12 px-5 pt-4 pb-5">
          <div class="row">
            <div class="col-lg-8 pl-0">
              <h2>Envío de mensajes masivos</h2>
              <p class="mb-3">
                Debes seleccionar a los clientes que quieras enviar un mensaje de WhatsApp o envía por tags asignados.
              </p>
            </div>
            <div class="col-lg-4 pr-0">
              <div v-if="checkedRows == 0">
                <div class="label-box">
                  <label for="">Envía por tag</label>
                  <span class="text_sm">{{ values.length == 1 ? values.length + " lead." : values.length + " leads." }}</span>
                </div>
                <select
                  class="select_md w-100"
                  v-model="selectTag"
                  @change="filterTemplateTags(selectTag)"
                >
                  <option value>Selecciona un tag</option>
                  <option v-for="(tag, index) in listTags" :key="index" :value="tag.id">{{
                    tag.label_name
                  }}</option>
                </select>
              </div>
              <div v-else class="info_box bg_grey text-center">
                <p class="mb-3">Enviar a {{ checkedRows.length == 1 ? checkedRows.length + " lead seleccionado" : checkedRows.length + " leads seleccionados." }}</p>
                <span class="i-btn text_sm" @click="$emit('cleanSelectedLeads')">Limpiar</span>
              </div>
            </div>
          </div>
          <hr class="my-4">
          <div class="label-box mt-5 mb-4">
            <h3 class="mb-0">Plantillas</h3>
            <div class="row align-items-center">
              <select
                class="select_text mb-0"
                v-model="selectLabel"
                @change="filterByLabel($event)"
              >
                <option value>Mostrar todas</option>
                <option v-for="(label, index) in templateLabel" :key="index" :value="label.id">
                {{  label.label_name[0].toUpperCase() + label.label_name.slice(1) }}
                </option>
              </select>
              <div class="input_box ml-3">
                <span class="btn-date px-2">
                  <i class="fas fa-search mr-0" />
                </span>
                <input
                  placeholder="Buscar por nombre"
                  class="input_search sm mb-0"
                  type="search"
                  v-model="search"
                />
              </div>
            </div>
          </div>
          <div v-if="filteredTemplates.length > 0" class="scroll_box pl-0">
            <div
              class="my-4 fade_down p-relative"
              v-for="(template, templateIndex) in filteredTemplates"
              :key="templateIndex"
            >
              <template v-if="template.status === 'approved'">
                <div class="row justify-content-between">
                  <div class="col-lg-8 px-0">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <label>
                          {{ template.name }}
                        </label>
                        <div class="d-flex align-items-center flex-wrap">
                          <span
                            v-for="(tags, tagIndex) in template.payroll_labels"
                            class="tag-text text-state text-state-fill mr-2 mb-2"
                            :style="[
                              { backgroundColor: tags.color || '#767676' },
                            ]"
                            :key="tagIndex"
                            >
                            {{ tags.label_name }}
                          </span>
                        </div>
                      </div>
                      <span class="text-state approved ml-2">
                        {{ template.status === "approved" ? "Aprobado" : "" }}
                      </span>
                    </div>
                    <div tabindex="1" class="div_input">
                      {{ template.components.find(el => el.type === "BODY").text }}
                    </div>
                  </div>
                  <div
                    v-if="values.length > 0 || checkedRows.length > 0"
                    class="col-lg-3 d-flex align-items-end pr-0 p-relative"
                  >
                    <template v-if="selected_bot.id == 64 || selected_bot.id == 7">
                      <button
                        class="btn btn-base btn-custom-send sm"
                        :disabled="loading"
                        @click="sendTemplate(values, template)"
                        >
                        <i class="fas fa-paper-plane" />Enviar
                      </button>
                      <button
                        class="btn btn-base sm btn-custom-send-icon"
                        @click="setSelectedValues(values, template), show_date_menu = true"
                      >
                        <i class="fas fa-angle-down" />
                      </button>
                    </template>
                    <template v-else>
                      <button
                        class="btn btn-base sm"
                        :disabled="loading"
                        @click="sendTemplate(values, template)"
                        >
                        <i class="fas fa-paper-plane" />Enviar
                      </button>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div v-else>
            <div class="info_box fade_down">
              <img class="icon" src="/img/icons/15-not_found.svg" alt="databot" />
              <p class="text-center">No se encontraron mensajes</p>
            </div>
          </div>
          <FadeTransition v-show="show_date_menu" :duration="200">
            <div class="date_menu">
              <span class="i-btn i-btn-sm i-close" @click="show_date_menu = false" />
              <label for="">Día de envío</label>
              <input
                type="date"
                :min="min_date"
                :class="{ 'd-block': true, input_error: error == 'empty_field_date' }"
                v-model="selected_date"
              >
              <label class="mb-2">Hora de envío</label>
              <input
                type="time"
                :class="{ 'd-block': true, input_error: error == 'empty_field' }"
                v-model="selected_time"
              >
              <button
                class="btn btn-base sm py-2 mt-4 second"
                @click="createScheduledMesssage(selected_value, selected_template)"
                >Enviar
              </button>
            </div>
          </FadeTransition>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script>
import axios from "axios";
import environment from "@/environment";
import dashboard_api from "@/dashboard_api.js";
import dashboard_api_v2 from "@/dashboard_api_v2.js";
import whatsappService from "@/services/api/whatsapp";
import { mapState } from "vuex";
import { FadeTransition } from 'vue2-transitions';
import { findDuplicatePhones } from "@/utils/utils";

export default {
  components: {
    environment,
    FadeTransition
  },
  props: ["show", "templates", "user", "listTags", "checkedRows"],
  data() {
    return {
      loading: false,
      search: "",
      selectTag: "",
      values: [],
      templateLabel: [],
      selectLabel: "",
      filteredTemplates: this.templates,
      show_date_menu: false,
      selected_date: '',
      selected_time: '',
      selected_value: [],
      selected_template: {},
      set_hour: 8,
      error: ''
    };
  },
  mounted() {
    this.getTemplateLabels();
  },
  watch: {
    search: {
      immediate: true,
      handler(val) {
        let itemSearch;
        if (this.selectLabel) itemSearch = this.templates.filter(template => template.payroll_labels.some(label => label.id == this.selectLabel));
        else itemSearch = this.templates;
        if (!val) this.filteredTemplates = itemSearch;

        this.filteredTemplates = itemSearch.filter(item => {
          let text = item.components.find(el => el.type === "BODY").text;

          return [item.name, text].find(field => {
            return field
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(
                val
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
                  .trim()
              );
          });
        });
      },
    },
  },
  computed: {
    ...mapState(["selected_bot"]),

    min_date() {
      return this.$moment().format('YYYY-MM-DD');
    },
    current_date() {
      return this.$moment().format('DD/MM/YYYY');
    }
  },
  methods: {
    setSelectedValues(value, template) {
      console.log("value->", value);
      console.log("template->", template);

      this.selected_value = value;
      this.selected_template = template;
    },
    filterByLabel(event) {
      const tagId = event.target.value;
      let tempItem = this.templates.filter(item => {
        let text = item.components.find(el => el.type === "BODY").text;
        return [item.name, text].find(field => {
          return field
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              this.search
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .trim()
            );
        });
      });
      if (tagId) tempItem = tempItem.filter(template => template.payroll_labels.some(label => label.id == tagId));
      this.filteredTemplates = tempItem;
    },
    async filterTemplateTags(id) {
      id ? this.values = (await dashboard_api_v2.get(`/leads_label_bots/leads_by_label/${id}`)).data : this.values = [];
    },
    async sendTemplate(values, template) {
      try {
        if (this.loading) return;
        this.loading = true;

        // in case is loading, just return to prevent double click
        if (this.checkedRows && this.checkedRows.length > 0) {
          values = this.checkedRows;
        }

        // Check for duplicates
        const { duplicates, uniqueLeads } = findDuplicatePhones(values);
        const hasDuplicates = duplicates.length > 0;

        // If there are duplicates, show warning first
        if (hasDuplicates) {
          const duplicateWarningResult = await this.$swal({
            title: "¡Se encontraron números duplicados!",
            html: `
              <p>Los siguientes números están duplicados:</p>
              <div class="info_box bg_grey">
                <div class="scroll_box">
                  <div class="row">
                    <div class="col-lg-6 pl-0">
                      ${duplicates.map(value => `<p class="text-left negrita">${value.name}</p>`).join("")}
                    </div>
                    <div class="col-lg-6 pr-0">
                      ${duplicates.map(value => `<p class="text-left">${value.phone}</p>`).join("")}
                    </div>
                  </div>
                </div>
              </div>
              <p class="mt-3" style="color: #ff9800;">¿Deseas continuar? El sistema enviará el mensaje a cada número único, pero se recomienda revisar los duplicados antes de continuar.</p>
            `,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sí, continuar",
            cancelButtonText: "Cancelar",
          });

          if (!duplicateWarningResult.isConfirmed) {
            this.loading = false;
            return;
          }
        }

        let html = `
          <p>¿Estás listo? Este mensaje se enviará a los siguientes Leads (Solo se muestran los primeros 20):
          ${hasDuplicates ? `<p style="color: #ff9800;"><i class="fas fa-exclamation-triangle"></i> Se detectaron ${duplicates.length} números duplicados. El mensaje se enviará una sola vez a cada número.</p>` : ''}
          <div class="col-lg-12 my-4">
            <div class="info_box bg_grey">
              <div class="scroll_box">
                <div class="row">
                  <div class="col-lg-6 pl-0">
                    ${values
                      .map(value => `<p class="text-left negrita">` + value.name + `</p>`)
                      .join(" ")}
                  </div>
                  <div class="col-lg-6 pr-0">
                    ${values.map(value => `<p class="text-left">` + value.phone + `</p>`).join(" ")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        `;
      // verificando si algunos leads no tienen datos dinamicos para completar plantilla
      // console.log("PLANTILLA: ", template);
      // console.log("LEADS: ", values);
      // console.log("USER: ", this.user);

      let dangerLeads = this.checkLeadsTemplateData(values, template);
      // adding second validation just in case tags
      let isInProgress;
      let isInside24h;
      if (this.selectTag) {
            ({ isInProgress, isInside24h } = await this.checkRepeatedTemplateDeliveries(template.name, { tagId: this.selectTag }));
      }
      if (dangerLeads.length > 0) {
        html += `
        <p>Los siguientes leads no cuentan con los datos suficientes para completar el mensaje de plantilla:</p>
        <div class="col-lg-12 my-4">
            <div class="info_box">
              <div class="scroll_box">
                <div class="row">
                  <div class="col-lg-6 pl-0">
                    ${dangerLeads
                      .map(value => `<p class="text-left negrita">` + value.name + `</p>`)
                      .join(" ")}
                  </div>
                  <div class="col-lg-6 pr-0">
                    ${dangerLeads
                      .map(value => `<p class="text-left">` + value.phone + `</p>`)
                      .join(" ")}
                  </div>
                </div>
              </div>
            </div>
          </div>`;
      }
      this.$swal({
        title: "Enviar mensaje de plantilla",
        html,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar"
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (isInProgress) {
            this.$swal({
              icon: "error",
              title: "¡Espera!",
              text: `⚠️ Esta plantilla se está enviando actualmente, espera a que termine para enviarla de nuevo.`,
              showConfirmButton: true
            });
            this.loading = false;
            return;
          }
          if (isInside24h) {
            const secondAlert = await this.$swal({
              icon: "warning",
              title: "¡Espera!",
              text: `⚠️ Recientemente enviaste esta plantilla por etiqueta, ¿quieres enviarla de nuevo?`,
              showConfirmButton: true,
              showCancelButton: true,
              cancelButtonText: "Cancelar"
            });
            if (!secondAlert.isConfirmed) {
              this.loading = false;
              return;
            }
          }

          this.$swal({
            icon: "success",
            title: "¡Listo! estamos enviando los mensajes, esto puede tardar algunos minutos.",
            timer: 3000,
            showConfirmButton: false
          });

          axios
            .post(environment.socket_url + "/whatsapp/360dialog/send-template-message/massive", {
              botId: this.selected_bot.id,
              phones: uniqueLeads.map(e => e.phone),
              leads: uniqueLeads.map(e => ({
                name: e.name,
                email: e.email,
                phone: e.phone,
                id: e.id,
                custom: e.custom,
                session_id: e.session_id
              })),
              name: template.name,
              tagId: this.selectTag,
            });
            this.unarchiveConversationsLeads(uniqueLeads);
            // .then(response => {
              // // se verifica si hubo algun telefono al que no le llego la plantilla
              // let failedNumbers = response.data.payload.failed.phones;
              // if (failedNumbers.length > 0) {
              //   // fallaron algunos numeros
              //   // this.$swal({
              //   //   icon: "info",
              //   //   title: "Listo, pero verifica estos números",
              //   //   text: `⚠️ El mensaje no se pudo enviar a los siguientes números: ${failedNumbers.join(
              //   //     ", "
              //   //   )}`,
              //   //   showConfirmButton: true
              //   // });
              //   // se guardan los numeros fallidos en sessionStorage, para resaltarlos en CRM
              //   this.$emit("onFailedNumbers", failedNumbers);
              //   sessionStorage.setItem("failed_numbers", JSON.stringify(failedNumbers));
              //   this.loading = false;
              // } else {
              //   // todo se envio correctamente
              //   // this.$swal({
              //   //   icon: "success",
              //   //   title: "¡Mensaje enviado!",
              //   //   timer: 1000,
              //   //   showConfirmButton: false
              //   // });
              //   this.loading = false;
              //   let leadIds = [];

              //   values.forEach(el => {
              //     leadIds.push(el.id);
              //   });
              // }
            //   this.unarchiveConversationsLeads(values);
            // });

          this.loading = false;
        }
      });
      this.loading = false;
      } catch (error) {
        console.log("Some error sending template messages: ", error);
      } finally {
        this.loading = false;
      }
    },
    unarchiveConversationsLeads(leads) {
      for (const lead of leads) {
        if (
          lead.channel === "Whatsapp Bot" &&
          lead.databot_lives &&
          lead.databot_lives.length > 0 &&
          lead.databot_lives[0].chat_rooms &&
          lead.databot_lives[0].chat_rooms.length > 0
        ) {
          let chatRooms = lead.databot_lives[0].chat_rooms;
          for (const chatRoom of chatRooms) {
            // es probable que haya varios chatRooms por desarchivar
            if (chatRoom.channel === "Whatsapp Bot") {
              dashboard_api.put(`/chat_rooms/${chatRoom.room_token}`, {
                archived: false
              });
            }
          }
        }
      }
    },
    checkLeadsTemplateData(leads, template) {
      /**
       * Hay constantes en el lead que tienen nombre diferente al tratarla como variable del sistema para plantilla
       * name = $lead.nombre
       * email = $lead.correo
       * phone = $lead.telefono
       * Los demás campos (dentro del campo custom, mantienen su mismo nombre como variable del sistema)
       */
      // buscando variables dinamicas usadas por mensaje plantilla
      let dynamicVariables = template.components
        .filter(el => el.custom_parameters && el.custom_parameters.length > 0)
        .reduce((acc, current) => [...acc, ...current.custom_parameters], [])
        .flat();
      let filteredLeads = leads.filter(
        lead =>
          !dynamicVariables.every(variable => {
            return (
              Object.keys(lead.custom).includes(variable.replace("$lead.", "")) ||
              (variable === "$lead.nombres" && lead.name && lead.name !== "--") ||
              (variable === "$lead.correo" && lead.email) ||
              (variable === "$lead.phone" && lead.telefono)
            );
          })
      );
      return filteredLeads;
    },
    async getTemplateLabels() {
      try {
        const res = await dashboard_api.get(`/label_payroll_bots/label_payroll_by_bot/${this.selected_bot.id}`);
        if (!res.data.message) {
          this.templateLabel = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async checkRepeatedTemplateDeliveries(templateName, { tagId } = {}) {
      try {
        let isInProgress = false;
        let isInside24h = false;
        const res = await whatsappService.getDeliveriesByBot(this.selected_bot.id);
        const deliveries = res.data.payload;
        const deliveriesByTemplate = deliveries.filter(delivery => delivery.templateName === templateName && delivery.tagId == tagId);
        if (deliveriesByTemplate && deliveriesByTemplate.length > 0) {
          isInProgress = deliveriesByTemplate.some(delivery => delivery.status === "in_progress");
          isInside24h = deliveriesByTemplate.some(delivery => delivery.isInside24h);
        }
        return {
          isInProgress,
          isInside24h
        };
      } catch (error) {
        console.log(error);
      }
    },
    async createScheduledMesssage(values, template) {
      this.error = '';

      try {
        if (this.checkedRows?.length > 0) {
          values = this.checkedRows;
        }
        if (this.selected_date == '' || this.selected_time == '') {
          if (this.selected_time == '') this.error = 'empty_field';
          if (this.selected_date == '') this.error = 'empty_field_date';

          this.$swal({
            icon: "error",
            title: "¡Espera!",
            text: `La hora y la fecha deben estar seleccionadas`,
            showConfirmButton: true
          });
          return;
        }
        const dateTime = await this.setDateTimeScheduledMessage(this.selected_date, this.selected_time);
        const payload = {
          scheduled_message: {
            bot_id: this.selected_bot.id,
            agent_name: this.user.name,
            agent_id: this.user.id,
            template_name: template.name,
            scheduled_time: dateTime,
            payroll_message_id: template.id,
            leads: values.map(e => ({
                name: e.name,
                email: e.email,
                phone: e.phone,
                id: e.id,
                custom: e.custom,
                session_id: e.session_id
              })),
            tag_id: this.selectTag
          }
        };
        const scheduled_message = await dashboard_api.post('/scheduled_messages', payload);
        if (scheduled_message.data) {
          this.$swal({
            icon: "success",
            title: "¡Listo!",
            text: `Plantilla programada con éxito.`,
            showConfirmButton: true
          });
          this.show_date_menu = false
        }
      } catch (error) {
        // console.log(error);
        this.$swal({
          icon: "error",
          title: "Ups",
          text: `Verifica que la fecha y/o la hora ingresada.`,
          showConfirmButton: true
        });
      }
    },
    async setDateTimeScheduledMessage(date, time) {
      const formattedDateTime = `${date}T${time}:00-03:00`; // Forzar zona horaria de Chile
      const datetime = new Date(formattedDateTime);
      return datetime.toISOString(); // Convertir a UTC correctamente
    },
    reset() {
      this.show_date_menu = false;
      this.selected_date = '';
      this.selected_time = '';
      this.selected_value = [];
      this.selected_template = {};
    }
  },
};
</script>

<style lang="scss">
.enviar-plantilla {
// for swal content
  .swal2-content {
    .info_box {
      padding: 0.25rem 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: auto;
      animation-name: first-fade-y-down;
      animation-duration: 0.5s;

      .row {
        padding: 1rem 0;
        border-bottom: 1px solid #e8e8e8;

        &:last-child {
          border-bottom: none;
        }
      }
      p {
        margin-bottom: 0;
      }
    }
    .scroll_box {
      width: 100%;
      max-height: 200px;
      padding: 0;
      overflow-y: scroll;

      @media (max-width: 480px) {
        padding: 0.5rem;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.enviar-plantilla {
  background-color: #fff;
  border-radius: 1rem;
  text-align: left;
  position: relative;

  .modal-content {
    width: 760px;
  }
  input {
    width: 100%;
  }
  .square_input {
    width: min(35px, 35px);
    height: min(30px, 30px);
    border-radius: .5rem;
    border: 1px solid #cdcdcd;
    background: #fff;
    transition: .25s;

    input {
      color: #767676;
      height: 100%;

      &:focus {
        color: #181818;
      }
    }
  }
  .square_input:has(input:focus) {
    border: 1px solid #2981EF;
  }
  .btn-base {
    width: 100%;
    margin: 0;
  }
  .div_input {
    font-size: 14px;
    line-height: 1.5rem;
    overflow-y: hidden;
    width: auto;
    height: 40px;
    background-color: transparent !important;
    box-shadow: none !important;
    color: #767676;
    border-radius: 0;
    border-bottom: 1px solid #cdcdcd;
    padding: 0.75rem 0;
    transition: 0.5s;
    margin: 0;

    &:hover {
      color: #181818;
      border-bottom: 1px solid #333;
    }
    &:focus {
      height: auto !important;
      overflow-y: auto !important;
      animation: show-navbar-dropdown 0.25s ease forwards;
      color: #767676;
      border-bottom: 1px solid #333;
    }
  }
  .scroll_box {
    max-height: 400px;
    padding-left: 14px;
    padding-right: 10px;
    overflow-y: scroll;

    @media (max-width: 480px) {
      padding: 0.5rem;
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
    /* Color de fondo y radius esquinas del thumb */
    &::-webkit-scrollbar-thumb {
      background: #e8e8e8;
      border-radius: 2rem;

      /* Cambiamos el fondo en hover */
      &:hover {
        background: #cdcdcd;
      }
      /* Cambiamos el fondo en active */
      &:active {
        background-color: #cdcdcd;
      }
    }
  }
  .text-state {
    margin: 0;
  }
  .fade_down {
    animation-name: first-fade-y-down;
    animation-duration: 0.5s;
  }
  .icon {
    display: flex;
    justify-content: center;
    margin: 0 auto 1rem;
  }
  .btn-custom-send {
    border-radius: 50rem 0 0 50rem;
  }
  .btn-custom-send-icon {
    width: 50px;
    border-left: 1px solid #767676;
    border-radius: 0 50rem 50rem 0;

    i {
      font-size: .75rem;
    }
  }
}
.opacity {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 0 0 1rem 1rem;
  background-color: #000;
  opacity: 30%;
  z-index: 99;
  width: 100%;
  height: 100%;
}
.date_menu {
  position: absolute;
  margin: 0 auto;
  width: 305px;
  bottom: 15%;
  left: 30%;
  right: auto;
  padding: 2rem 1.5rem 1.5rem 1.5rem;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  z-index: 100;

  input {
    font-size: 12px;
    padding: .25rem .5rem;
  }
  .input_time {
    cursor: pointer;
  }
  .i-close {
    position: absolute;
    top: .75rem;
    right: .25rem;
    padding: .25rem;
    background-size: .5rem;
    width: 25px;
    height: 25px;
  }
}
</style>
