<template>
  <section class="catalog-section">
    <FadeTransition :duration="200">
      <div class="container">
        <div class="my-4">
          <h1 class="mb-0 text-center">{{ showProducts ? `Productos de "${selectedCatalog?.name}"` : 'Catálogos Meta conectados' }}</h1>
        </div>

        <!-- Catalogs Section -->
        <div v-if="!showProducts" class="panel-container">
          <div class="row justify-content-between align-items-center b-bottom">
            <h3 class="my-2">Catálogos disponibles</h3>
            <div class="d-flex align-items-center">
              <button @click="showCatalogForm = true" class="btn-select static sm">
                <i class="fas fa-plus mr-2"></i>Crear catálogo
              </button>
            </div>
          </div>

          <div class="row" v-if="loading">
            <div class="col-12 text-center py-5">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Cargando...</span>
              </div>
            </div>
          </div>

          <div v-else class="row mt-4">
            <div v-if="catalogs.length === 0" class="col-12 text-center py-5">
              <img class="icon" src="/img/icons/15-not_found.svg" alt="No hay catálogos" />
              <p class="text-center text_sm my-3">
                No hay catálogos disponibles. Crea uno para comenzar.
              </p>
            </div>

            <div v-else class="col-12">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="text-left">#</th>
                      <th class="text-left">ID</th>
                      <th class="text-left">Nombre</th>
                      <th class="text-left">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(catalog, index) in catalogs" :key="catalog.id">
                      <td>{{ index + 1 }}</td>
                      <td>{{ catalog.id }}</td>
                      <td>{{ catalog.name }}</td>
                      <td>
                        <button class="btn-select static sm" @click="viewProducts(catalog)">
                          <i class="fas fa-eye mr-2"></i>Ver productos
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- Products Section -->
        <div v-if="showProducts" class="panel-container">
          <div class="row justify-content-between align-items-center b-bottom">
            <h3 class="my-2">Productos del catálogo</h3>
            <div class="d-flex align-items-center">
              <button class="btn-select static sm mr-3" @click="backToCatalogs">
                <i class="fas fa-arrow-left mr-2"></i>Volver
              </button>
              <button class="btn-select static sm" @click="showProductModal = true">
                <i class="fas fa-plus mr-2"></i>Agregar producto
              </button>
            </div>
          </div>

          <div class="row" v-if="loading">
            <div class="col-12 text-center py-5">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Cargando...</span>
              </div>
            </div>
          </div>

          <div v-else class="row mt-4">
            <div v-if="products.length === 0" class="col-12 text-center py-5">
              <img class="icon" src="/img/icons/15-not_found.svg" alt="No hay productos" />
              <p class="text-center text_sm my-3">
                No hay productos en este catálogo. Agrega uno para comenzar.
              </p>
            </div>

            <div v-else class="col-12">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="text-left">#</th>
                      <th class="text-left">Imagen</th>
                      <th class="text-left">Nombre</th>
                      <th class="text-left">ID</th>
                      <th class="text-left">Precio</th>
                      <th class="text-left">Descripción</th>
                      <th class="text-left">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(product, index) in products" :key="product.id">
                      <td>{{ index + 1 }}</td>
                      <td>
                        <img
                          :src="product.image_url"
                          alt=""
                          width="50px"
                          height="50px"
                          class="product-image"
                          v-if="product.image_url"
                        />
                        <span v-else class="no-image">Sin imagen</span>
                      </td>
                      <td>{{ product.name || 'Sin nombre' }}</td>
                      <td>{{ product.retailer_id || 'N/A' }}</td>
                      <td>{{ formatPrice(product) }}</td>
                      <td class="description-cell">
                        <span :title="product.description">{{ truncateText(product.description, 50) || 'Sin descripción' }}</span>
                      </td>
                      <td>
                        <div class="action-buttons">
                          <button class="action-btn edit-btn" @click="editProduct(product)" title="Editar">
                            <i class="fas fa-edit"></i>
                          </button>
                          <button class="action-btn delete-btn" @click="deleteProduct(product)" title="Eliminar">
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FadeTransition>

    <!-- Catalog Creation Modal -->
    <Modal
      class="modal-2"
      ancho="600"
      :show="showCatalogForm"
      @close="showCatalogForm = false"
    >
      <template slot="header">
        <h3 class="titulo__modal mb-0">Crear nuevo catálogo</h3>
      </template>
      <template slot="content">
        <section class="p-4">
          <div class="form-group">
            <label for="catalogName">Nombre del catálogo</label>
            <input
              type="text"
              id="catalogName"
              v-model="newCatalogName"
              class="form-control"
              placeholder="Ingrese nombre del catálogo"
            />
          </div>
          <div class="text-center mt-4">
            <button
              class="btn-select static md"
              @click="createCatalog"
              :disabled="!newCatalogName || loading"
            >
              <span v-if="loading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
              Crear catálogo
            </button>
            <button
              class="btn-secondary static md ml-2"
              @click="showCatalogForm = false"
            >
              Cancelar
            </button>
          </div>
        </section>
      </template>
    </Modal>

    <!-- Product Modal -->
    <Modal
      class="modal-2"
      ancho="800"
      :show="showProductModal"
      @close="cancelProductForm"
    >
      <template slot="header">
        <h3 class="titulo__modal mb-0">{{ editingProduct ? 'Editar producto' : 'Agregar nuevo producto' }}</h3>
      </template>
      <template slot="content">
        <section class="p-4">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Nombre del producto*</label>
                <input type="text" class="form-control" v-model="productForm.name" required />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>ID de producto (SKU/Referencia)*</label>
                <input type="text" class="form-control" v-model="productForm.retailer_id" required placeholder="ej: SKU123" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Precio*</label>
                <input type="number" class="form-control" v-model="productForm.price" required />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Moneda</label>
                <input type="text" class="form-control" v-model="productForm.currency" placeholder="CLP" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Disponibilidad</label>
                <select class="form-control" v-model="productForm.availability">
                  <option value="in stock">Disponible</option>
                  <option value="out of stock">Agotado</option>
                  <option value="preorder">Preventa</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Condición</label>
                <select class="form-control" v-model="productForm.condition">
                  <option value="new">Nuevo</option>
                  <option value="used">Usado</option>
                  <option value="refurbished">Restaurado</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Categoría</label>
                <input type="text" class="form-control" v-model="productForm.category" placeholder="ej: Ropa > Mujer > Vestidos" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Marca</label>
                <input type="text" class="form-control" v-model="productForm.brand" placeholder="ej: Nike" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>URL de la imagen principal*</label>
                <input type="text" class="form-control" v-model="productForm.image_url" placeholder="https://..." required />
                <small class="form-text text-muted">Esta será la imagen principal del producto.</small>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Descripción</label>
                <textarea class="form-control" v-model="productForm.description" rows="4"></textarea>
              </div>
            </div>

            <div class="col-12 text-center mt-3">
              <button
                class="btn-select static md mr-2"
                @click="saveProduct"
                :disabled="loading"
              >
                <span v-if="loading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                {{ editingProduct ? 'Actualizar' : 'Guardar' }}
              </button>
              <button class="btn-secondary static md" @click="cancelProductForm">
                Cancelar
              </button>
            </div>
          </div>
        </section>
      </template>
    </Modal>
  </section>
</template>

<script>
import { FadeTransition } from 'vue2-transitions';
import graphApiService from '@/services/api/graphApi';
import Modal from '@/components/Modal';

export default {
  components: {
    FadeTransition,
    Modal
  },
  data() {
    return {
      metaPages: [],
      catalogs: [],
      products: [],
      showProducts: false,
      selectedCatalog: null,
      TOKEN: localStorage.getItem('facebookAccessToken'),
      BUSINESS_ID: '109740223853637',
      newCatalogName: '',
      showCatalogForm: false,
      showProductModal: false,
      editingProduct: null,
      productForm: {
        name: '',
        price: '',
        currency: 'CLP',
        description: '',
        image_url: '',
        retailer_id: '',
        availability: 'in stock',
        condition: 'new',
        additional_image_urls: [],
        category: '',
        brand: ''
      },
      loading: false
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      // fetch all catalogs
      this.loading = true;
      graphApiService.listCatalogs(this.BUSINESS_ID, this.TOKEN)
        .then((response) => {
          this.catalogs = response.data.payload || [];
        })
        .catch((error) => {
          console.error('Error fetching catalogs:', error);
          this.showNotification('Error al cargar catálogos', 'error');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    viewProducts(catalog) {
      this.selectedCatalog = catalog;
      this.showProducts = true;
      this.loadProducts(catalog.id);
    },
    loadProducts(catalogId) {
      this.loading = true;
      graphApiService.listProductsByCatalog(catalogId, this.TOKEN)
        .then((response) => {
          if (response.data.payload && response.data.payload.data) {
            this.products = response.data.payload.data || [];
          } else {
            this.products = [];
            console.warn('Unexpected API response format:', response.data);
          }
        })
        .catch((error) => {
          console.error('Error fetching products:', error);
          this.showNotification('Error al cargar productos', 'error');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    backToCatalogs() {
      this.showProducts = false;
      this.selectedCatalog = null;
      this.showProductModal = false;
    },
    showNotification(message, type = 'success') {
      // Use SweetAlert for notifications
      const toastConfig = {
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000
      };

      if (type === 'success') {
        this.$swal({
          icon: 'success',
          title: message,
          ...toastConfig
        });
      } else {
        this.$swal({
          icon: 'error',
          title: 'Error',
          text: message,
          ...toastConfig
        });
      }
    },
    createCatalog() {
      if (!this.newCatalogName) return;

      this.loading = true;
      graphApiService.createCatalog(this.newCatalogName, this.BUSINESS_ID, this.TOKEN)
        .then((response) => {
          this.newCatalogName = '';
          this.showCatalogForm = false;
          // Refresh catalogs
          this.initialize();
          this.showNotification('Catálogo creado exitosamente');
        })
        .catch((error) => {
          console.error('Error creating catalog:', error);
          this.showNotification('Error al crear catálogo', 'error');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editProduct(product) {
      this.editingProduct = product;
      // Log the product object to check the price value
      console.log('Editing product:', product);
      console.log('Product price:', product.price, typeof product.price);

      this.productForm = {
        name: product.name || '',
        // Ensure price is treated as a number for the input field
        price: product.price !== null && product.price !== undefined ? Number(product.price) : '',
        currency: product.currency || 'CLP',
        description: product.description || '',
        image_url: product.image_url || '',
        retailer_id: product.retailer_id || '',
        availability: product.availability || 'in stock',
        condition: product.condition || 'new',
        additional_image_urls: product.additional_image_urls || [],
        category: product.category || '',
        brand: product.brand || ''
      };
      this.showProductModal = true;
    },
    saveProduct() {
      // Dynamically build productData with non-empty fields from productForm
      const form = this.productForm;
      const productData = {};
      const fields = ['name', 'description', 'price', 'currency', 'image_url', 'retailer_id', 'availability', 'condition', 'brand', 'category'];

      fields.forEach(field => {
        if (form[field] !== '' && form[field] !== null && form[field] !== undefined) {
          productData[field] = form[field];
        }
      });

      // Special handling for retailer_id during creation if not provided
      if (!this.editingProduct && !productData.retailer_id) {
        productData.retailer_id = `product_${Date.now()}`;
      }

      // Special handling for images array based on image_url
      if (productData.image_url) {
        productData.images = [{ url: productData.image_url, tags: [] }];
      }

      // Ensure price is a number if present
      if (productData.price) {
          productData.price = Number(productData.price);
      }

      // Prevent sending empty payload
      if (Object.keys(productData).length === 0) {
           this.showNotification('No hay datos para guardar.', 'error');
           return;
      }

      // If creating, you might still want basic checks for API required fields
      if (!this.editingProduct && (!productData.name || !productData.price || !productData.image_url || !productData.retailer_id)) {
          this.showNotification('Faltan campos requeridos para crear un producto (Nombre, Precio, Imagen, ID).', 'error');
          return; // Stop if essential fields for creation are missing
      }

      this.loading = true;
      console.log('Sending product data:', productData); // Log the data being sent

      const apiCall = this.editingProduct
        ? graphApiService.updateProductCatalog(this.editingProduct.id, productData, this.TOKEN)
        : graphApiService.createProductCatalog(productData, this.selectedCatalog.id, this.TOKEN);

      apiCall
        .then((response) => {
          this.showProductModal = false;
          this.loadProducts(this.selectedCatalog.id);
          const message = this.editingProduct ? 'Producto actualizado exitosamente' : 'Producto creado exitosamente';
          this.showNotification(message);
          // No need to call cancelProductForm here, it's called on modal close
        })
        .catch((error) => {
          console.error(`Error ${this.editingProduct ? 'updating' : 'creating'} product:`, error);
          const message = `Error al ${this.editingProduct ? 'actualizar' : 'crear'} producto`;
          // Try to get more specific error from API response if available
          const apiError = error.response?.data?.error?.message || error.message;
          this.showNotification(`${message}: ${apiError}`, 'error');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancelProductForm() {
      this.showProductModal = false;
      this.editingProduct = null;
      this.productForm = {
        name: '',
        price: '',
        currency: 'CLP',
        description: '',
        image_url: '',
        retailer_id: '',
        availability: 'in stock',
        condition: 'new',
        additional_image_urls: [],
        category: '',
        brand: ''
      };
    },
    deleteProduct(product) {
      if (confirm('¿Está seguro que desea eliminar este producto?')) {
        this.loading = true;
        graphApiService.deleteProductCatalog(product.id, this.TOKEN)
          .then(() => {
            // Refresh products
            this.loadProducts(this.selectedCatalog.id);
            this.showNotification('Producto eliminado exitosamente');
          })
          .catch((error) => {
            console.error('Error deleting product:', error);
            this.showNotification('Error al eliminar producto', 'error');
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    formatPrice(product) {
      if (!product.price) return 'No disponible';
      return `${product.price} ${product.currency || 'CLP'}`;
    },
    truncateText(text, length) {
      if (!text) return '';
      return text.length > length ? text.substring(0, length) + '...' : text;
    }
  },
  computed: {
    // isValidProduct computed property is removed
  },
};
</script>

<style lang="scss" scoped>
.catalog-section {
  .panel-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin-bottom: 2rem;
  }

  .b-bottom {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }

  .btn-select {
    background-color: #2A81EF;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    transition: all 0.3s ease;

    &:hover {
      background-color: darken(#2A81EF, 10%);
    }

    &.xs {
      padding: 0.1rem 0.3rem;
      font-size: 11px;
    }
  }

  .btn-danger {
    background-color: #F5365C;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    transition: all 0.3s ease;

    &:hover {
      background-color: darken(#F5365C, 10%);
    }

    &.xs {
      padding: 0.1rem 0.3rem;
      font-size: 11px;
    }
  }

  .btn-secondary {
    background-color: #b2b2b2;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    transition: all 0.3s ease;

    &:hover {
      background-color: darken(#b2b2b2, 10%);
    }
  }

  .product-image {
    border-radius: 4px;
    object-fit: cover;
  }

  .no-image {
    color: #b2b2b2;
    font-size: 12px;
  }

  .description-cell {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .table {
    th {
      font-weight: 600;
      color: #444;
    }

    tr:hover {
      background-color: #f8f9fa;
    }
  }

  .icon {
    width: 80px;
    margin-bottom: 1rem;
  }

  .action-buttons {
    display: flex;
    gap: 8px;
  }

  .action-btn {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    transition: all 0.2s ease;
    padding: 0;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    }

    &.edit-btn {
      background-color: #2A81EF;

      &:hover {
        background-color: darken(#2A81EF, 10%);
      }
    }

    &.delete-btn {
      background-color: #F5365C;

      &:hover {
        background-color: darken(#F5365C, 10%);
      }
    }
  }
}

// Modal styles
.titulo__modal {
  text-align: center;
  font-weight: 600;
  color: #444;
  font-size: 1.5rem;
}
</style>
