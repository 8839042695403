<template>
  <section class="detail-id">
    <FadeTransition :duration="200">
      <SpinnerLoading v-if="loading" />
      <div v-else class="container">
        <div class="row justify-content-center">
          <!-- LADO IZQUIERDO -->
          <div class="col-lg-4 mt-5">
            <router-link
              class="btn-text"
              :to="{ path: `/documento-conocimiento` }"
              >&lt;- Volver</router-link
            >
            <div class="row mt-3">
              <h3>Editar documento</h3>
            </div>
            <div class="row mt-5">
              <label>Nombre</label>
              <input
                v-model="document.title"
                type="text"
                class="input_2"
                placeholder="Ingresa un nombre"
              />
            </div>
            <div class="mt-5">
              <label for="">Formatos compatibles</label>
              <div class="row">
                <div class="two_box">
                  <ul class="pl-3">
                    <li class="text_sm">.PDF</li>
                    <li class="text_sm">.TXT</li>
                    <li class="text_sm">.CSV</li>
                    <li class="text_sm">.XLSX</li>
                    <li class="text_sm">.XLS</li>
                    <li class="text_sm">.PPTX</li>
                  </ul>
                </div>
                <div>
                  <ul>
                    <li class="text_sm">.PPT</li>
                    <li class="text_sm">.PPTX</li>
                    <li class="text_sm">.DOCX</li>
                    <li class="text_sm">.DOC</li>
                    <li class="text_sm">.Web</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row my-5">
              <button class="btn btn-base" @click="updateDocument">
                Guardar
              </button>
            </div>
          </div>
          <div class="col-lg-2"></div>
          <!-- LADO DERECHO -->
          <div v-if="document.has_segments" class="col-lg-6">
            <div class="doc_box scroll_box">
              <h3 class="mb-5">{{ document?.title }}</h3>
              <div
                v-for="segment in document.documents_segments"
                :key="segment._id"
                class="mb-3"
              >
                <hr class="my-4">
                <label>Página {{ segment.page }}</label>
                <p class="mb-0">{{ segment.content }}</p>
              </div>
            </div>
          </div>
          <div v-else class="scroll_box col-lg-6 px-5 pt-4" style="background-color: white; height: 80vh; overflow: auto; margin-botton: 5px">
            <div >
            <div class="d-flex justify-content-between align-items-center pb-5">
              <h4>{{ document?.title }}</h4>
              <div class="d-flex justify-content-center align-items-center">
                <span class="pr-4 text-dark">{{ document?.content?.length }} caracteres</span>
                <!-- <button class="export-btn px-2 py-1">Exportar</button> -->
              </div>
            </div>
            <div class="text-break">
              {{ document.content }}
            </div>
          </div>
          </div>
        </div>
      </div>
    </FadeTransition>
  </section>
</template>
<script>
import axios from 'axios';
import environment from '@/environment.js';
import { mapState } from 'vuex';
import { FadeTransition } from 'vue2-transitions';

export default {
  name: 'knowledge-detail',
  components: { FadeTransition },
  computed: {
    ...mapState(['selected_bot']),
  },
  data() {
    return {
      loading: true,
      assistantId: null,
      document: {
        title: ""
      }
    };
  },
  async mounted() {
    try {
      await this.getAssistantId();
      await this.getDocument();
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async updateDocument() {
      try {
        await axios.put(
          `${environment.RASA_URL}/api/assistants/${this.assistantId}/documents/${this.$route.params.id}`,
          {
            title: this.document?.title,
          },
        );
        this.$swal({
          icon: 'success',
          title: 'Documento modificado',
          showConfirmButton: false,
          timer: 1000,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getAssistantId() {
      try {
        const res = await axios.get(
          `${environment.RASA_URL}/api/assistants/by_bot_id/${this.selected_bot.id}`,
        );
        this.assistantId = res.data.payload.assistant_id;
      } catch (error) {
        console.error('error getAssistantId', error);
      }
    },
    async getDocument() {
      const res = await axios.get(
        `${environment.RASA_URL}/api/assistants/${this.assistantId}/documents/${this.$route.params.id}`,
      );
      this.document = res.data.payload;
    },
  },
};
</script>
<style lang="scss" scoped>
select {
  width: 100%;
}
.export-btn {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #ccc;
  color: #666;
}
.scroll_box {
  &::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
.doc_box {
  padding: 2.5rem;
  max-height: calc(100vh - 80px);
  overflow: auto;
  background-color: #fff;

  p {
    line-height: 170%;
  }
  @media (max-width: 768px) {
    max-height: 100%;
  }
}
</style>
