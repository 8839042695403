var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{key:_vm.selected_bot.id,class:{
     agent_status_history: true,
     item3: _vm.show_client_info,
   }},[_c('div',{class:{
       'item item-1 pb-0': true,
       hidden: _vm.showMobileViewChat || _vm.show_client_info,
       showMobileMode: !_vm.showMobileViewChat && !_vm.show_client_info,
     }},[_c('AgentsList',{attrs:{"selectedAgent":_vm.selectedAgent,"agents":_vm.listAgents,"isLoadingAgents":_vm.isLoadingAgents},on:{"onSelectedAgent":_vm.selectAgent,"onSearchChat":_vm.searchAgent,"openDateModal":function($event){_vm.show_date_filter = true}}})],1),_c('div',{class:{
       'item item-2 fadeItemMobile': true,
       hidden: !_vm.showMobileViewChat,
       showMobileMode: _vm.showMobileViewChat,
     }},[_c('HistoryTable',{attrs:{"history":_vm.history,"isLoadingHistory":false,"selectedAgent":_vm.selectedAgent,"itemsPerPage":_vm.itemsPerPage,"actualPage":_vm.currentPage,"pages":_vm.pages,"totalItems":_vm.totalItems,"rangeDate":{fromDate: _vm.fromDate,endDate: _vm.endDate}},on:{"changePagination":_vm.changePagination}})],1),_c('DateFilterModal',{attrs:{"showFilterModal":_vm.show_date_filter},on:{"closeModal":function($event){_vm.show_date_filter = false},"filterDate":_vm.sendDate}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }